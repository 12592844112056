import { Address, zeroAddress } from 'viem'
import { getLPTokenPrice } from './getLpPrice'
import { cronosTokens, USD } from '@pancakeswap/tokens'
import { fetchTokenPrice } from './ObsidianPricing'
import { WETH9 } from '@pancakeswap/sdk'

const baseUrl = 'https://api.dexscreener.com/latest/dex/tokens/'

// Wolf Swap specific token addresses and their corresponding IDs
interface WolfSwapToken {
  address: string
  id: number
}

// LP Token related interfaces
export interface LPPair {
  token0Address: string
  token1Address: string
}

export interface TokenConfig {
  isLpPool?: boolean
  lpPair?: LPPair
}

// Wolf Swap specific token address
const WOLF_SWAP_TOKENS: WolfSwapToken[] = [
  { address: '0xCb49dd81da680ed11d10809B51d5D5D8e36F9B6D', id: 327 },
  { address: '0x0F1E83378baa83e000492d6d1eB09CBC9E4628E6', id: 458 },
  { address: '0x91e8BB36c3ec72a24A15455a077ECA20A701bB84', id: 619 },
  // Add more Wolf Swap tokens here as needed
  // Example: { address: '0x...', id: 328 }
]

const WOLF_SWAP_API_BASE = 'https://api.wolfswap.app/launcher/token'

interface TokenPair {
  baseToken: {
    address: string
    name: string
    symbol: string
  }
  quoteToken: {
    address: string
    name: string
    symbol: string
  }
  priceUsd: string
  liquidity: {
    usd: number
  }
}

interface WolfSwapResponse {
  lastPrice: string
  graduated: boolean
  dexPair: string | null
  // ... other fields if needed
}

// Helper function to check if address is a Wolf Swap token and get its ID
const isWolfswapToken = (address: string): string | null => {
  const token = WOLF_SWAP_TOKENS.find(
    (t) => t.address.toLowerCase() === address.toLowerCase()
  )
  return token ? token.address : null
}

// New function to fetch Wolf Swap token price
const fetchWolfSwapPrice = async (
  chainId: number,
  tokenAddress: string
): Promise<string | null> => {
  if (!tokenAddress) return null
  try {
    // First get the token info from Wolf Swap
    const response = await fetch(`${WOLF_SWAP_API_BASE}?address=${tokenAddress}&networkId=${chainId}`)
    const data = (await response.json()) as WolfSwapResponse
    
    // If token has graduated, return null to fall back to DexScreener
    if (data.graduated) {
      console.info(`Token ${tokenAddress} has graduated from Wolf Swap, falling back to DexScreener`)
      return null
    }

    // Get the WETH price in USD for non-graduated tokens
    const wethPriceUrl = `${baseUrl}${WETH9[chainId].address}`
    const wethResponse = await fetch(wethPriceUrl)
    const wethData = await wethResponse.json()
    const wethPairs = wethData?.pairs || []
    
    // Find the WETH/USD price from the highest liquidity pair
    const validWethPairs = wethPairs
      .filter((pair: TokenPair) => pair.priceUsd !== undefined && pair.liquidity?.usd > 0)
      .sort((a: TokenPair, b: TokenPair) => b.liquidity.usd - a.liquidity.usd)

    let wethUsdPrice: number

    if (!validWethPairs.length) {
      // Fallback to Obsidian API for WETH price
      const wethPrice = await fetchTokenPrice(chainId, WETH9[chainId].address)
      if (!wethPrice) {
        console.warn('Unable to fetch WETH price')
        return null
      }
      wethUsdPrice = parseFloat(wethPrice)
    } else {
      wethUsdPrice = parseFloat(validWethPairs[0].priceUsd)
    }
    
    // Calculate USD price
    const priceInWeth = parseFloat(data.lastPrice)
    const priceInUsd = (priceInWeth * wethUsdPrice).toString()
    return priceInUsd
  } catch (error) {
    console.warn(`Error fetching Wolf Swap price for token ${tokenAddress}:`, error)
    return null
  }
}

// Helper function to get stable tokens for a chain
const getChainStableTokens = (chainId: number): string[] => {
  const stableTokens: string[] = []

  if (USD[chainId]) {
    stableTokens.push(USD[chainId].address)
  }

  switch (chainId) {
    case 25:
      if (cronosTokens.usdc) {
        stableTokens.push(cronosTokens.usdc.address)
      }
      break
  }

  return [...new Set(stableTokens)].filter(Boolean)
}

export const dexScreenerUSD = async (
  chainId: number, 
  tokenAddresses: string[], 
  tokenConfigs?: Record<string, TokenConfig>
) => {
  if (!tokenAddresses.length) return new Map<string, string>()

  const filteredAddresses = tokenAddresses.filter((address) => address !== zeroAddress)
  if (!filteredAddresses.length) return new Map<string, string>()

  const tokenUrl = `${baseUrl}${filteredAddresses.join(',')}`
  const chainStableTokens = getChainStableTokens(chainId)
  const commonTokenUSDValue = new Map<string, string>()

  const remainingAddresses = [...filteredAddresses]
  for (const tokenAddress of filteredAddresses) {
    if (isWolfswapToken(tokenAddress) !== null) {
      const wolfSwapPrice = await fetchWolfSwapPrice(chainId, tokenAddress)
      if (wolfSwapPrice) {
        commonTokenUSDValue.set(tokenAddress, wolfSwapPrice)
        // Remove the Wolf Swap token from remaining addresses to skip regular processing
        const index = remainingAddresses.indexOf(tokenAddress)
        if (index > -1) {
          remainingAddresses.splice(index, 1)
        }
      }
    }
  }

  if (chainId === 388) {
    for (const tokenAddress of filteredAddresses) {
      const price = await fetchTokenPrice(chainId, tokenAddress)
      if (price) {
        commonTokenUSDValue.set(tokenAddress, price)
      }
    }
    return commonTokenUSDValue
  }

  try {
    const result = await fetch(tokenUrl).then((res) => res.json())
    const tokensData: TokenPair[] = result?.pairs || []

    for (const tokenAddress of filteredAddresses) {
      const lowercaseTokenAddress = tokenAddress.toLowerCase()
      const tokenConfig = tokenConfigs?.[tokenAddress]

      if (chainStableTokens.some((stableToken) => stableToken.toLowerCase() === lowercaseTokenAddress)) {
        commonTokenUSDValue.set(tokenAddress, '1')
        continue
      }

      if (tokenConfig?.isLpPool && tokenConfig.lpPair) {
        // Use LP pair information to calculate price
        const lpTokenPrice = await getLPTokenPrice(
          chainId, 
          tokenAddress as Address, 
          tokenConfig.lpPair.token0Address as Address,
          tokenConfig.lpPair.token1Address as Address
        )
        commonTokenUSDValue.set(tokenAddress, lpTokenPrice.toString())
      } else {
        const tokenPairs = tokensData.filter(
          (pair) => pair.baseToken.address.toLowerCase() === lowercaseTokenAddress
        )

        const validTokenPairs = tokenPairs
          .filter((pair) => pair.priceUsd !== undefined && pair.liquidity?.usd > 0)
          .sort((a, b) => b.liquidity.usd - a.liquidity.usd)

        if (validTokenPairs.length > 0) {
          const highestLiquidityPair = validTokenPairs[0]
          const tokenPrice = parseFloat(highestLiquidityPair.priceUsd)

          if (!isNaN(tokenPrice)) {
            commonTokenUSDValue.set(tokenAddress, tokenPrice.toString())
          } else {
            const obsidianPrice = await fetchTokenPrice(chainId, tokenAddress)
            if (obsidianPrice) {
              commonTokenUSDValue.set(tokenAddress, obsidianPrice)
            } else {
              console.warn(`Invalid price value for token: ${tokenAddress}`)
            }
          }
        } else {
          const obsidianPrice = await fetchTokenPrice(chainId, tokenAddress)
          if (obsidianPrice) {
            commonTokenUSDValue.set(tokenAddress, obsidianPrice)
          } else {
            console.warn(`No valid pairs found where token ${tokenAddress} is the base token`)
          }
        }
      }
    }

    return commonTokenUSDValue
  } catch (error) {
    console.warn('Error while fetching token price:', error)
    
    for (const tokenAddress of filteredAddresses) {
      const price = await fetchTokenPrice(chainId, tokenAddress)
      if (price) {
        commonTokenUSDValue.set(tokenAddress, price)
      }
    }
    
    return commonTokenUSDValue
  }
}