export const smartChefFactoryABI = [
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_initialFee',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: '_initialCollector',
        type: 'address',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'smartChef',
        type: 'address',
      },
    ],
    name: 'NewSmartChefContract',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'newFee',
        type: 'uint256',
      },
    ],
    name: 'DeploymentFeeUpdated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'newCollector',
        type: 'address',
      },
    ],
    name: 'FeeCollectorUpdated',
    type: 'event',
  },
  {
    inputs: [],
    name: 'deploymentFee',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'feeCollector',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_newFee',
        type: 'uint256',
      },
    ],
    name: 'updateDeploymentFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_newCollector',
        type: 'address',
      },
    ],
    name: 'updateFeeCollector',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract IERC20Metadata',
        name: '_stakedToken',
        type: 'address',
      },
      {
        internalType: 'contract IERC20Metadata',
        name: '_rewardToken',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_rewardPerBlock',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_startBlock',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_bonusEndBlock',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_poolLimitPerUser',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_numberBlocksForUserLimit',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_minStakingPeriod',
        type: 'uint256',
      },
      {
        internalType: 'bool',
        name: '_useInitialLockPeriod',
        type: 'bool',
      },
      {
        internalType: 'address',
        name: '_manager',
        type: 'address',
      },
    ],
    name: 'deployPool',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'contract IERC20Metadata',
        name: '_stakedToken',
        type: 'address',
      },
      {
        internalType: 'contract IERC20Metadata',
        name: '_rewardToken',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_startBlock',
        type: 'uint256',
      },
    ],
    name: 'predictPoolAddress',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    stateMutability: 'payable',
    type: 'receive',
  },
  {
    inputs: [],
    name: 'emergencyWithdraw',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
] as const;