import IceChain from '../ice-chain'

export const cronoszk: IceChain = {
  id: 388,
  name: 'Cronos zkEVM',
  features: ['swap', 'staking'],
  network: 'cronoszk',
  rpcUrls: {
    public: { http: ['https://mainnet.zkevm.cronos.org',] },
    default: { http: ['https://mainnet.zkevm.cronos.org',] },
  },
  blockExplorers: {
    default: { name: 'Cronos zkEVM Explorer', url: 'https://explorer.zkevm.cronos.org' },
  },
  nativeCurrency: {
    name: 'zkCRO',
    symbol: 'zkCRO',
    decimals: 18,
  },
  contracts: {
    multicall3: {
      address: '0xCD0F0898442BD0dBA8B7f89dCab0F51ECa128C7b',
      blockCreated: 74083,
    },
  },
  blockInterval: 14.5,
  wrappedNative: {
    address: '0xC1bF55EE54E16229d9b369a5502Bfe5fC9F20b6d',
    decimals: 18,
    symbol: 'wzkCRO',
    name: 'Wrapped zkCRO',
  },
  iceAddress: '0x664E4b17EA045fE92868821F3eE0a76a5dB38166',
  stableToken: {
    address: '0x5b91e29Ae5A71d9052620Acb813d5aC25eC7a4A2',
    decimals: 18,
    symbol: 'vUSD',
    name: 'Veno USD',
  },
  swap: {
    factoryAddress: '0xEDc17bf0f27afc2e767cA08aE426d095207A7804',
    initCodeHash: '0x0100053bebd525bbc9cc7030eb1f3692de927beb0f45b16cb76401062f37c377',
    routerAddress: '0x958015F48Cdf1B5BF520C0559455cb62632C1729',
  },
  locks: {
    factoryAddress: '0xA759C444A1d4C2eC64a12ed6a46b0454cE55Ca61',
  },
  //farmV2Address: '0x8a44aC7D38B9925D2437803520ED38ae5C3120e5',
  /*
  v3:{
    factoryAdress:'0x94fFa46F0831311152F0dbe5D4C74d54268533d5'
    initCodeHash: '0xf16b1c067611794c79bef6ff7630d4a4c43b3be76c08085ce934747817a6b322',
  },
  */
  smartRouterAddress: '0xDb0837D207708F55549a425638de3E2f53Eea141',
  // self service contracts
  smartChefDeployer: '0x60a3916db477804C12bDB705cDE7496e485AE813',
  airdropAddress: '0x75824163e0982138c2BC8492262a669760e86171',
  nftMasterDeployer: '0xE957CE6387C5852d89604436b42654Ca992e3985',
}