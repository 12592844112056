type RefetchFunction = () => Promise<void>;

class TradeStatusService {
  private subscribers: Set<RefetchFunction> = new Set();

  async notifySubscribers(): Promise<void> {
    // Call all registered refetch functions
    const promises = Array.from(this.subscribers).map(refetchFn => refetchFn());
    await Promise.all(promises);
  }
  
  register(refetchFn: RefetchFunction): () => void {
    this.subscribers.add(refetchFn);
    return () => {
      this.subscribers.delete(refetchFn);
    };
  }
}

// Create a singleton instance
export const tradeStatusService = new TradeStatusService();